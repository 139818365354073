
import { getSliceComponentProps } from '@prismicio/vue/components'
import {
  DatabaseIcon,
  ChartPieIcon,
  CalendarIcon,
  ChartBarIcon,
  ClipboardIcon,
  CloudUploadIcon,
  CloudDownloadIcon,
  DesktopComputerIcon,
  DeviceTabletIcon,
  DeviceMobileIcon,
  LockClosedIcon,
  ServerIcon,
  UserGroupIcon,
  TrendingUpIcon,
  ShareIcon,
  ShieldCheckIcon,
  GlobeAltIcon,
  FastForwardIcon,
  ClockIcon,
} from '@vue-hero-icons/outline'

import FieldHockeyIcon from '../../components/basics/icons/field-hockey-icon.vue'
import IceHockeyIcon from '../../components/basics/icons/ice-hockey-icon.vue'
import SoccerIcon from '../../components/basics/icons/soccer-icon.vue'
import VolleyballIcon from '../../components/basics/icons/volleyball-icon.vue'
import BasketballIcon from '../../components/basics/icons/basketball-icon.vue'
import OfficialsIcon from '../../components/basics/icons/officials-icon.vue'
import StudentIcon from '../../components/basics/icons/student-icon.vue'
import TeacherIcon from '../../components/basics/icons/teacher-icon.vue'

export default {
  name: 'FeatureList',
  // The array passed to `getSliceComponentProps` is purely optional and acts as a visual hint for you
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  data() {
    return {
      icons: {
        database: DatabaseIcon,
        'chart-pie': ChartPieIcon,
        calendar: CalendarIcon,
        'chart-bar': ChartBarIcon,
        clipboard: ClipboardIcon,
        'cloud-upload': CloudUploadIcon,
        'cloud-download': CloudDownloadIcon,
        desktop: DesktopComputerIcon,
        'globe-alt': GlobeAltIcon,
        tablet: DeviceTabletIcon,
        mobile: DeviceMobileIcon,
        'lock-close': LockClosedIcon,
        server: ServerIcon,
        share: ShareIcon,
        'shield-check': ShieldCheckIcon,
        'user-group': UserGroupIcon,
        'trending-up': TrendingUpIcon,
        Feldhockey: FieldHockeyIcon,
        Fussball: SoccerIcon,
        Eishockey: IceHockeyIcon,
        Basketball: BasketballIcon,
        Volleyball: VolleyballIcon,
        Offizielle: OfficialsIcon,
        Spieler: StudentIcon,
        Trainer: TeacherIcon,
        forward: FastForwardIcon,
        clock: ClockIcon,
      },
    }
  },
}
